/* Base Styles */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  font-family: 'Roboto', sans-serif !important; /* Updated font family to Roboto */
  font-size: 1rem;
  line-height: 1.6;
  background-color: #ffffff; 
  color: #333333; 
}

/* General Container for Contact Us Page */
.contact-us-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  padding: 0 20px;
  background-color: #ffffff;
  margin-top: 30px;
}

/* Main Title Above Image and Text */
.contact-us-title {
  font-size: 2.8rem;
  text-align: center;
  font-weight: bold;
  color: #222222;
  margin-bottom: 30px;
  width: 100%;
  font-family: 'Roboto', sans-serif; /* Updated to Roboto */
}

/* Section Layout Adjustments */
.contact-us-section {
  display: flex;
  flex-direction: row; /* Image on left, text on right */
  justify-content: space-between;
  align-items: center;
  padding: 40px; 
  background-color: #ffffff;
  border-radius: 10px;
  max-width: 1200px;
  margin: 0 auto;
  gap: 40px;
}

/* Left Image Container - Larger Image */
.contact-us-image-container {
  flex: 7; /* Larger image to emphasize */
  max-width: 70%;
}

.contact-us-image-container img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  object-fit: cover;
  height: auto; 
}

/* Right Text Content */
.contact-us-text-content {
  flex: 3; /* Smaller width for text to emphasize image */
  text-align: left;
  padding: 0 40px;
  max-width: 40%;
}

.contact-us-text-content h2 {
  font-size: 2rem; 
  color: #222222;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.4;
  font-family: 'Roboto', sans-serif; /* Updated to Roboto */
}

.contact-us-text-content p {
  font-size: 1.2rem;
  line-height: 1.8;
  color: #555555;
  margin-bottom: 20px;
  font-family: 'Roboto', sans-serif; /* Updated to Roboto */
}

/* Button Styling */
.btn {
  background-color: #007BFF;
  color: white;
  border: none;
  padding: 15px 25px;
  font-size: 1rem;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
  transform: scale(1.05);
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .contact-us-section {
      flex-direction: column;
      text-align: center;
      padding: 20px;
      gap: 20px;
  }

  .contact-us-title {
      font-size: 2rem;
      margin-bottom: 20px;
  }

  .contact-us-image-container {
      max-width: 100%;
  }

  .contact-us-text-content {
      max-width: 100%;
      padding: 20px;
  }

  .contact-us-text-content h2 {
      font-size: 1.5rem;
  }

  .contact-us-text-content p {
      font-size: 1rem;
  }
}

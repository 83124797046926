:root {
  --primary-white: #ffffff;
  --primary-black: #000000;
  --primary-green: #193320;
  --secondary-green: #013D1D;
  --teal-color: #F9F8DA;
}

/* General Navbar Styling */
.custom-navbar {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  padding: 15px 50px;
  z-index: 2000;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 0;
  border: none;
}

/* Logo Styling */
.navbar-logo {
  width: 160px;
  height: auto;
  margin-right: 20px;
}

/* Navbar Links Container with Solid Black Box */
.navbar-links {
  display: flex;
  gap: 0;
  background: var(--primary-black);
  padding: 0;
  border-radius: 0;
}

/* General Styling for All Links and Dropdown Items */
.nav-item, .dropdown-item {
  color: white !important;
  font-size: 1.1rem;
  padding: 12px 25px;
  border: none;
  background: transparent;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
}

/* Home Link Specific Styling - Made Wider */
.nav-link.home-link {
    padding: 12px 35px !important; /* Increased padding for a wider box */
    color: white !important;
    font-size: 1.1rem;
    border: none;
    background: transparent;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    justify-content: center;
    align-items: center;
    display: flex;
}

/* Fix Bootstrap link colors */
.navbar .nav-item a, .dropdown-item a {
  color: white !important;
  text-decoration: none !important;
}

/* Hover Effect (Consistent for All Items) */
.nav-item:hover, .dropdown-item:hover {
  background: rgba(255, 255, 255, 0.2); 
  color: white !important;
}

/* Active Link Styling (Same for Both Nav and Dropdown) */
.nav-item.active, .dropdown-item.active {
  background: rgba(255, 255, 255, 0.2) !important; 
  color: white !important;
  border: none;
}

/* Dropdown Styling (Matching Main Navbar Styling) */
.nav-item .dropdown-menu {
  background: var(--primary-black) !important; 
  border: none;
  padding: 0;
  border-radius: 0;
}

.dropdown-item {
  font-size: 1.1rem;
  transition: all 0.3s ease;
}

/* Fix for Bootstrap's default caret */
.navbar .dropdown-toggle::after {
  color: white !important;
}

/* Mobile Styling */
@media (max-width: 768px) {
  .custom-navbar {
      padding: 15px;
  }

  .navbar-links {
      flex-direction: column;
  }

  .navbar-logo {
      width: 130px;
  }

  .nav-item, .dropdown-item {
      padding: 12px 20px;
      text-align: center;
  }

  .dropdown-item {
      padding: 12px 20px;
  }
}

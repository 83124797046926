:root {
    --font-montserrat: 'Montserrat', sans-serif;
    --primary-color: #000000;
    --background-color: #ffffff;
    --card-background-color: #ffffff; 
}

.portfolio-container {
    min-height: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: var(--background-color);
    color: #333;
    padding: 20px;
    box-sizing: border-box;
    position: relative;
    z-index: 100;
    width: 100%;
    margin: 0;
    font-family: var(--font-montserrat);
    text-align: center;
    padding-bottom: 200px; /* Padding at the bottom */
    padding-top: 50px; /* Padding at the top */
}

.portfolio-title {
    text-align: center;
    margin-bottom: 40px;
    background-color: #000000; /* Add background color */
    padding: 20px; /* Add padding for spacing */
    display: inline-block;
    border-radius: 5px; /*rounded corners */
}

.portfolio-title h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #ffffff; /* Change text color to white for contrast */
    position: relative;
    display: inline-block;
}

.portfolio-title h2::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 5px;
    background-color: #ffffff; /* White underline */
    bottom: -10px;
    left: 0;
}

.property-carousel {
    width: 100%;
    margin: 0 auto;
}

.slick-slide {
    padding: 0 5px;
    padding-bottom: 80px; /* Padding below each slide */
}

.property-card {
    border: none;
    border-radius: 8px;
    overflow: hidden;
    margin-bottom: 20px; /* Space between cards */
    width: 100%;
    max-width: 400px; /* Increased max-width for larger cards */
    height: 520px; /* Increased height for larger cards */
    font-family: var(--font-montserrat);
    padding: 0;
    background-color: var(--card-background-color);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: transform 0.5s ease-in-out, box-shadow 0.5s ease-in-out;
}

.property-card:hover {
    transform: scale(1.05);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.property-card .property-card-img {
    max-height: 320px; /* Increased height for images */
    width: 100%;
    object-fit: cover; /* Change to cover for better image fit */
    display: block;
    margin: 0 auto;
}

.property-card .card-body {
    padding: 15px; /* Padding within the card body */
    color: #000000;
    flex-grow: 1; /* Allow card body to expand */
}

.property-card .card-title {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 1rem;
}

.property-card .card-text {
    font-size: 0.9rem;
    color: #000000;
}

.property-card .btn-primary {
    background-color: #000000;
    border-color: #000000;
    color: #ffffff;
    font-size: 0.8rem;
    padding: 8px 16px;
    transition: background-color 0.5s ease-in-out, transform 0.5s ease-in-out;
    margin-top: 15px; /* Margin above the button for spacing */
}

.property-card .btn-primary:hover {
    background-color: #FFFFFF; /* Green background on hover */
    color: #ffffff; /* White text on hover */
    border-color: #000000; 
}

/* Tabs for switching between available and upcoming properties */
.portfolio-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.portfolio-tab {
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    text-decoration: none;
    color: #333;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-color 0.3s;
}

.portfolio-tab:hover {
    color: #1D4D2D;
    border-color: #FFFFFF;
}

.portfolio-tab.active {
    color: #1D4D2D;
    border-color: #FFFFFF;
    font-weight: bold;
}

/* Responsive adjustments for tablets and smaller screens */
@media (max-width: 768px) {
    .property-carousel {
        width: 90%;
    }

    .slick-slide {
        padding: 0 10px; /* Less padding for smaller screens */
    }

    .property-card {
        max-width: 100%;
        margin-bottom: 20px;
        height: auto; /* Allow height to adjust for mobile */
    }

    .property-card .card-title {
        font-size: 1rem;
    }

    .property-card .card-text {
        font-size: 0.8rem;
    }

    .property-card .btn-primary {
        font-size: 0.7rem;
    }
}

/* Tabs for switching between available and upcoming properties */
.portfolio-tabs {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.portfolio-tab {
    padding: 10px 20px;
    margin: 0 5px;
    font-size: 16px;
    text-decoration: none;
    color: #333;
    border-bottom: 2px solid transparent;
    transition: color 0.3s, border-color 0.3s;
}

.portfolio-tab:hover {
    color: #FFFFFF;
    border-color: #000000;
}

.portfolio-tab.active {
    color: #000000;
    border-color: #000000;
    font-weight: bold;
}


/* Further adjustments for mobile devices */
@media (max-width: 576px) {
    .portfolio-container {
        padding-bottom: 150px; /* Increased bottom padding */
    }

    .property-carousel {
        width: 100%;
    }

    .slick-slide {
        padding: 0 5px; /* Minimal padding for mobile */
    }

    .property-card {
        max-width: 100%;
        margin-bottom: 20px;
        height: auto; /* Allow height to adjust for mobile */
    }

    .property-card .card-title {
        font-size: 0.9rem;
    }

    .property-card .card-text {
        font-size: 0.7rem;
    }

    .property-card .btn-primary {
        font-size: 0.6rem;
    }
}

.statistics-section { 
    display: flex;
    justify-content: space-around;
    align-items: flex-end; /* Align items at the bottom */
    padding: 18px 0 22px; /* Reduced bottom padding from 28px to 22px */
    background: black; 
    color: white;
    text-align: center;
    width: 100%;
    min-height: 16vh; /* Adjusted height slightly larger */
    box-sizing: border-box;
    border-top: 2px solid rgba(255, 255, 255, 0.1); 
}

/* Individual Stat Items Styling */
.stat-item {
    flex: 1;
    padding: 38px 10px; 
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-end; /* Align text near bottom */
}

/* Vertical Divider Between Items */
.stat-item:not(:last-child)::after {
    content: "";
    position: absolute;
    right: 0;
    top: 20%;
    height: 60%;
    width: 1.5px;
    background: rgba(255, 255, 255, 0.7);
}

/* Gold Number Styling */
.stat-item h2 {
    font-size: 2rem;
    color: #013D1D;
    margin: 0; 
    font-weight: bold;
    line-height: 1; 
}

/* White Subtitle Styling */
.stat-item p {
    font-size: 1rem;
    color: white;
    margin: 5px 0 0; 
    text-transform: uppercase;
    letter-spacing: 1px;
    opacity: 0.9;
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .statistics-section {
        flex-direction: column;
        padding: 36px 0; 
        min-height: auto; /* Reset for mobile */
    }

    .stat-item {
        padding: 36px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    .stat-item:not(:last-child)::after {
        display: none;
    }
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.property-detail-container {
    font-family: 'Suranna-Regular', sans-serif;
    min-height: calc(100vh - 250px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    background-color: #FFFFFF;
    color: #333;
    padding: 0;
    position: relative;
    z-index: 100;
    width: 100%;
    margin: 0;
    padding-bottom: 300px;
    border-radius: 15px;
    overflow: hidden;
}

/* Spacing adjustment for the content area */
.property-detail-content {
    margin-top: 40px; /* Space between the hero and content */
    display: flex; /* Ensures the image and details section are side by side */
    flex-wrap: wrap; /* Ensure proper wrapping for responsive design */
}

/* Heading styles */
.property-detail-container h3 {
    font-family: 'Suranna-Regular', sans-serif;
    font-weight: bold;
    margin-bottom: 20px;
    color: #000000;
}

/* Card styles */
.property-detail-container .card {
    margin-bottom: 20px; /* Spacing below the card */
    background-color: #ffffff;
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 500px; /* Set a fixed width for consistency */
    height: 480px; /* Set a fixed height */
}

/* Carousel image styles */
.property-detail-container .card-img-top {
    height: 480px;
    object-fit: cover;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    cursor: pointer;
}

/* Padding and width adjustments for the card body */
.property-detail-container .card-body {
    padding: 30px; /* Maintain padding for content */
    border-color: #000000;
    border-radius: 20px;
}

/* Carousel container adjustments */
.carousel-container {
    padding-right: 30px;
    width: 65%; /* Adjusted width to allow room for property details */
    flex: 0 0 65%; /* Flex properties to ensure the width is respected */
    padding-top: 100px; /* Adjust for spacing as needed */
    display: flex; /* Centering carousel */
    justify-content: center; /* Centering carousel */
    align-items: center; /* Center carousel vertically */
}

/* Property details card adjustments */
.property-details-card {
    flex: 0 0 35%; /* Flex properties to align next to the carousel */
    align-self: flex-start; /* Ensures the details section aligns to the top */
    margin-top: 100px; /* Increase this value to move the card down */
}

/* Popup styles */
.image-popup {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1000;
}

.popup-image {
    max-width: 80%;
    max-height: 80%;
    border-radius: 10px;
}

/* Column adjustments for padding */
.property-detail-container .col-md-6 {
    padding-left: 30px; /* Adjusted padding */
    padding-right: 30px; /* Adjusted padding */
}

/* New styles to better mimic the Domain Development site */
.property-detail-content h2 {
    font-size: 28px; /* Adjust heading size for property name */
    color: #000000; /* Darker shade for property title */
    margin-bottom: 15px; /* Spacing below title */
}

.property-location {
    font-size: 20px; /* Font size for location */
    color: #757575; /* Lighter shade for location */
}

.property-description {
    font-size: 16px; /* Font size for property description */
    line-height: 1.5; /* Better readability */
    color: #5A5A5A; /* Subtle color for description text */
}

.property-details {
    font-size: 16px; /* Font size for property details */
    color: #333; /* Darker shade for details */
    margin-bottom: 10px; /* Spacing below details */
}

.property-contact {
    font-size: 16px; /* Font size for contact info */
    color: #333; /* Consistent color for contact info */
    margin-bottom: 15px; /* Spacing below contact info */
}

/* Button styles */
.property-details-card .btn {
    background-color: #000000; /* Dark green background */
    color: #fff; /* White text color */
    transition: background-color 0.3s ease; /* Smooth transition for hover */
}

.property-details-card .btn:hover {
    background-color: #FFFFFF; /* Darker green on hover */
}

/* Mobile-Only Adjustments for Correcting Alignment */
@media (max-width: 768px) {
    .align-items-start.row {
        flex-direction: column; /* Stack elements vertically */
        width: 100%; /* Ensure it takes the full width */
        margin: 0; /* Remove any excess margins that might cause alignment issues */
    }

    .property-detail-content {
        flex-direction: column; /* Stack elements vertically */
        align-items: center; /* Center-align elements */
        margin-top: 20px; /* Adjust margin for spacing */
    }

    .carousel-container {
        width: 100%; /* Full width on mobile */
        padding: 0; /* Remove all padding */
        padding-top: 10px; /* Less padding on top */
        display: flex; /* Ensure the container behaves as a flexbox */
        justify-content: center; /* Center the carousel horizontally */
        align-items: center; /* Center the carousel vertically */
        margin: 0 auto; /* Ensure horizontal centering */
        text-align: center; /* Ensure content is centered */
    }

    .property-detail-container .card {
        width: 100%; /* Full width for the card on mobile */
        margin: 0 auto; /* Center the card horizontally */
    }

    .property-detail-container .card-img-top {
        width: 100%; /* Ensure the image is full width */
        max-width: 100%; /* Ensure it scales properly */
        height: auto; /* Maintain aspect ratio */
        object-fit: cover; /* Ensure the image is not distorted */
    }

    .property-details-card {
        flex: 0 0 100%; /* Full width for details card on mobile */
        margin-top: 20px; /* Adjust margin for mobile */
    }

    .property-detail-content h2 {
        font-size: 24px; /* Smaller font size for mobile */
        text-align: center; /* Center align for better appearance */
    }

    .property-location {
        font-size: 18px; /* Smaller font size for location */
        text-align: center; /* Center align for better appearance */
    }

    .property-description,
    .property-details,
    .property-contact {
        font-size: 14px; /* Smaller font size for mobile */
        text-align: center; /* Center align for better appearance */
    }

    .property-details-card .btn {
        width: 100%; /* Full-width button on mobile */
        padding: 12px; /* Increase padding for better touch targets */
    }
}

@media (max-width: 576px) {
    .property-detail-content h2 {
        font-size: 22px; /* Further reduce size for extra small screens */
    }

    .property-location {
        font-size: 16px; /* Further reduce location font size */
    }

    .property-description,
    .property-details,
    .property-contact {
        font-size: 12px; /* Further reduce font size for mobile */
    }

    .property-details-card .btn {
        padding: 12px; /* Increase padding for better touch targets */
    }
}




/* Capabilities Section Styling */
.capabilities-section {
    text-align: center;
    padding: 40px 20px; /* Reduced top padding */
    background-color: #FFFFFF;
    color: #000000;
    border-radius: 15px;
    margin-bottom: 80px;
    box-sizing: border-box;
}

/* Title Styling */
.capabilities-title {
    font-size: 3.5rem; /* Increased font size for a bigger title */
    font-weight: bold;
    color: #222222;
    margin-bottom: 60px; /* Added padding below the title for better spacing */
    text-align: center;
}

/* Flexbox Container for Horizontal Layout */
.capabilities-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 40px;
    max-width: 1200px;
    margin: 0 auto;
}

/* Individual Capability Item Styling */
.capability-item {
    flex: 1;
    min-width: 250px;
    max-width: 300px;
    padding: 20px;
    border-radius: 15px;
    text-align: center;
    background: #ffffff;
    transition: transform 0.3s ease-in-out;
}

.capability-item:hover {
    transform: scale(1.05);
}

/* Circular Icon Styling */
.capability-icon-circle {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #013D1D; /* Dark green circle background */
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto 20px;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
}

.capability-icon {
    font-size: 3rem;
    color: #ffffff;
}

/* Capability Title and Text Styling */
.capability-item h3 {
    font-size: 1.8rem;
    margin-bottom: 10px;
    font-weight: bold;
}

.capability-item p {
    font-size: 1rem;
    line-height: 1.6;
    color: #555555;
    margin-bottom: 0;
}

/* Mobile Optimization */
@media (max-width: 768px) {
    .capabilities-container {
        flex-direction: column;
        align-items: center;
    }

    .capability-item {
        max-width: 100%;
    }
}

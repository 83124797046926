/* fonts.css */

@font-face {
    font-family: 'Suranna-Regular';
    src: url('../fonts/Suranna-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Montserrat';
    src: url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');
    font-weight: normal;
    font-style: normal;
  }
  
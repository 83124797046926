/* Hero Section Styling */
.hero {
  height: 85vh; /* Increased video size from 70vh to 85vh */
  width: 100%;
  position: relative;
  overflow: hidden;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Video Background Styling - Adjusted for visibility */
.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Make sure it covers the entire hero section */
  z-index: -1;
  opacity: 1; /* Ensure full visibility */
  display: block; /* Make sure it's visible */
  pointer-events: none;
}

/* Hero Content Styling */
.hero-content {
  z-index: 2;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 90%;
  color: #F9F8DA;
  font-weight: 700;
  padding: 0; /* Removed padding to reduce white space */
  margin: 0; /* Removed margin to reduce white space */
}

/* Adjusting Header Text Size */
.hero h1 {
  font-size: 3rem;
  margin-bottom: 1rem;
}

/* Paragraph Styling */
.hero p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

/* Button Styling */
.hero-button {
  padding: 10px 20px;
  font-size: 1rem;
  color: white;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.hero-button:hover {
  background-color: #0056b3;
}

/* Mobile Adjustments */
@media (max-width: 768px) {
  .hero {
      height: 60vh; /* Slightly increased for mobile visibility */
  }

  .hero h1 {
      font-size: 2rem;
  }

  .hero p {
      font-size: 1.2rem;
  }

  .hero-button {
      font-size: 0.9rem;
  }
}
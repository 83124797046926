/* Make sure the body and html take full height */
body, html {
  height: 100%;
  margin: 0;
}

/* Footer styling */
.footer {
  position: fixed; /* Ensure the footer stays fixed at the bottom */
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #000000;
  color: white;
  padding: 10px 0;
  text-align: center;
  z-index: 1000;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.footer.show {
  transform: translateY(0);
}

.footer .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer ul {
  list-style-type: none;
  padding: 0;
  margin-bottom: 1rem;
}

.footer ul li {
  display: inline-block;
  margin-right: 20px;
}

.footer ul li a {
  text-decoration: none;
  color: inherit;
  font-size: 1rem;
}

.footer .social-icons {
  margin-bottom: 1rem;
}

.footer .social-icons a {
  margin-right: 10px;
  color: inherit;
  text-decoration: none;
  font-size: 1.5rem;
}

.footer p {
  margin: 0;
}

/* Additional styles to ensure footer stays at the bottom */
footer {
  position: fixed; /* Keeps footer fixed at bottom */
  bottom: 0;
  width: 100%;
  background-color: #000000;
  color: white;
  text-align: center;
  padding: 10px 0;
  z-index: 1000;
  transform: translateY(100%);
  transition: transform 0.3s ease;
}

.footer.show {
  transform: translateY(0);
}

/* If the footer does not stay at the bottom when content overflows, use below */
body {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

main {
  flex-grow: 1; /* Pushes footer to the bottom */
}


/* Featured Properties Section Styling */
.featured-properties-section {
    margin-top: 60px; /* Added padding above the featured properties section */
    padding: 20px;
    padding-bottom: 80px; /* Slightly reduced padding for better spacing */
    text-align: center;
    background-color: #ffffff; /* Changed to white background */
}

/* Title Styling */
.main-title {
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: bold;
    color: #222;
}

/* Carousel Styling */
.property-carousel {
    padding: 20px;
}

/* Property Card Styling */
.property-card {
    border: none;
    box-shadow: 0 4px 8px rgba(0,0,0,0.1);
    transition: transform 0.3s ease-in-out;
}

.property-card:hover {
    transform: scale(1.05);
}

/* Property Card Image Styling */
.property-card-img {
    border-radius: 10px 10px 0 0;
    height: 220px;
    object-fit: cover;
}
